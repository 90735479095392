<template>
	<div class="page-admin-clerkminutes">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">ClerkMinutes</h2>
			</div>
			<div class="col-auto">
				<router-link to="/heygov-admin/workflows/create" class="btn btn-outline-primary btn-sm disabled">
					<font-awesome-icon :icon="['fas', 'file']" class="me-1" /> Export CSV
				</router-link>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="row align-items-center mb-3">
					<div class="col-auto">
						<div class="btn-group">
							<button
								class="btn btn-sm"
								:class="filters.state === 'active' ? 'btn-primary' : 'btn-outline-primary'"
								@click="filters.state = 'active'"
							>
								Active / Trial
							</button>
							<button
								class="btn btn-sm"
								:class="filters.state === 'canceled' ? 'btn-primary' : 'btn-outline-primary'"
								@click="filters.state = 'canceled'"
							>
								Canceled
							</button>
						</div>
					</div>

					<div class="col">
						<p class="my-0">Found {{ jurisdictions.length }} ClerkMinutes accounts.</p>
					</div>
				</div>

				<template v-if="filters.state === 'active'">
					<p class="mb-1">Goal: 800 paying customers</p>
					<div class="grid-container" style="gap: 3px">
						<router-link
							v-for="jurisdiction in jurisdictions"
							:key="jurisdiction.slug"
							:to="`/heygov-admin/jurisdiction/${jurisdiction.slug}`"
							class="grid-item"
							:style="{
								background: jurisdiction.subscription
									? statusToColor[jurisdiction.subscription.status]
									: '#475569',
							}"
							:title="jurisdiction.name"
						></router-link>
						<div
							v-for="index in 800 - jurisdictions.length"
							:key="index"
							class="grid-item"
							style="background: #cbd5e1"
						></div>
					</div>
				</template>

				<hr class="bg-success-200" />

				<div class="card-table">
					<table class="table table-hover">
						<thead
							class="position-sticky"
							style="top: 0; background-color: rgba(255, 255, 255, 0.7); backdrop-filter: blur(10px)"
						>
							<tr>
								<th>Customer</th>
								<th>Meetings</th>
								<th title="Minutes generated / AI Actions / Downloads">Usage</th>
								<th
									title="How much HeyGov paid AI services (audio transcript, generate minutes, AI actions) for this municipality"
								>
									AI $$
								</th>
								<th>Plan</th>
								<th>Plan status</th>
								<th><small>Payment Method</small></th>
								<th><small>Population</small></th>
								<th>Referrer</th>
								<th>Last usage</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="jurisdiction in jurisdictions" :key="jurisdiction.id">
								<td>
									<p class="mb-0">
										<router-link :to="`/heygov-admin/jurisdiction/${jurisdiction.slug}`">{{
											jurisdiction.name
										}}</router-link>
									</p>
									<p class="mb-0">
										{{ [jurisdiction.location.city, jurisdiction.location.state].join(', ') }}
									</p>
								</td>
								<td>
									<router-link :to="`/${jurisdiction.slug}/meetings`">{{
										jurisdiction.meetings.toLocaleString()
									}}</router-link>
								</td>
								<td>
									<span title="Minutes generated">{{
										jurisdiction.minutes_generated.toLocaleString()
									}}</span>
									/
									<span title="AI actions">{{
										jurisdiction.minutes_ai_actions.toLocaleString()
									}}</span>
									/
									<span title="Downloads">{{ jurisdiction.minutes_downloads.toLocaleString() }}</span>
								</td>
								<td>
									{{ jurisdiction.ai_cost | currency }}
								</td>
								<td>
									<a
										:href="
											`https://dashboard.stripe.com/customers/${jurisdiction.stripe_customer_id}`
										"
									>
										<template v-if="jurisdiction.subscription && jurisdiction.subscription.plan">
											{{ (jurisdiction.subscription.plan.amount / 100) | currency }} /
											{{ jurisdiction.subscription.plan.interval }}
										</template>
										<font-awesome-icon v-else :icon="['fab', 'stripe-s']" />
									</a>
								</td>
								<td>
									<template v-if="jurisdiction.subscription">
										<p class="mb-1">
											<span
												v-if="jurisdiction.subscription.status === 'active'"
												class="badge bg-success-50 text-success-400"
												>Active</span
											>
											<template v-else-if="jurisdiction.subscription.status === 'trialing'">
												<span class="badge bg-warning-50 text-warning-400">Trial</span> ends
												<span :title="jurisdiction.subscription.trial_end | dateLocal">{{
													jurisdiction.subscription.trial_end | dateToNow
												}}</span>
											</template>
											<span
												v-else-if="jurisdiction.subscription.status === 'past_due'"
												class="badge bg-danger-50 text-danger-400"
												>Past DUE</span
											>
											<code v-else>{{ jurisdiction.subscription.status }}</code>
										</p>
										<p class="mb-0">{{ jurisdiction.subscription.created | dateLocal }}</p>
									</template>
								</td>
								<td>
									<template
										v-if="
											jurisdiction.subscription &&
												jurisdiction.subscription.default_payment_method
										"
									>
										<span
											v-if="jurisdiction.subscription.default_payment_method.type === 'card'"
											class="bg-light d-inline-block px-2 rounded-1"
										>
											<font-awesome-icon
												:icon="[
													'fab',
													`cc-${jurisdiction.subscription.default_payment_method.card.brand}`,
												]"
												class="text-primary"
											/>
											{{ jurisdiction.subscription.default_payment_method.card.last4 }}
										</span>
										<span
											v-else-if="
												jurisdiction.subscription.default_payment_method.type ===
													'us_bank_account'
											"
											class="bg-light d-inline-block px-2 rounded-1"
										>
											<font-awesome-icon
												:icon="['fas', `building-columns`]"
												class="text-primary"
											/>
											{{ jurisdiction.subscription.default_payment_method.us_bank_account.last4 }}
										</span>
										<code v-else>{{ jurisdiction.subscription.default_payment_method.type }}</code>
									</template>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td @dblclick="updateJurisdictionField(jurisdiction, 'population_count')">
									<span v-if="jurisdiction.population_count">{{
										jurisdiction.population_count.toLocaleString()
									}}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td @dblclick="updateJurisdictionField(jurisdiction, 'referrer')">
									<small v-if="jurisdiction.referrer">{{ jurisdiction.referrer }}</small>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td>
									{{ jurisdiction.last_usage | dateLocal }}
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.jurisdictions === 'loading'">
								<td colspan="11" class="text-center">
									<span class="spinner-border spinner-border-sm"></span> Loading..
								</td>
							</tr>
							<tr v-else-if="states.jurisdictions === 'loaded'">
								<td></td>
								<th>{{ sum(jurisdictions.map(j => j.meetings)) }}</th>
								<th>
									{{ sum(jurisdictions.map(j => j.minutes_generated)) }} /
									{{ sum(jurisdictions.map(j => j.minutes_ai_actions)) }} /
									{{ sum(jurisdictions.map(j => j.minutes_downloads)) }}
								</th>
								<th>{{ sum(jurisdictions.map(j => j.ai_cost)) | currency }}</th>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<pre v-if="$route.query.dev == 1">{{ jurisdictions }}</pre>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.grid-container {
	display: grid;
	grid-template-columns: repeat(100, 1fr); /* 100 items per row */
	gap: 3px; /* Adjust the gap between items as needed */
}

.grid-container .grid-item {
	width: 100%;
	aspect-ratio: 1;
	border-radius: 50%;
}
</style>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { sum } from 'lodash-es'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'AdminClerkMinutes',
	components: {},
	data() {
		return {
			states: {
				jurisdictions: 'loading',
			},
			jurisdictions: [],
			filters: {
				state: 'active',
				search: this.$route.query.search || '',
			},
			sorting: {
				orderBy: localStorage.getItem('admin-jurisdictions-order-by') || 'event_app_open',
				order: localStorage.getItem('admin-jurisdictions-order') || 'desc',
			},

			statusToColor: {
				active: '#22c55e',
				trialing: '#fde047',
				past_due: '#ef4444',
			},
		}
	},

	created() {
		this.loadJurisdictions()
	},
	computed: {
		...mapState(['apiUrl']),
	},
	methods: {
		sum,

		loadJurisdictions() {
			this.jurisdictions = []
			this.states.jurisdictions = 'loading'

			const params = {
				...this.filters,
				...this.sorting,
			}

			heyGovApi.get('super-secret-admin-endpoints/clerkminutes', { params }).then(({ data }) => {
				this.jurisdictions = data

				this.states.jurisdictions = 'loaded'
			}, handleResponseError('Failed to load jurisdictions ({error})'))
		},
		updateJurisdictionField(jurisdiction, field) {
			let value = prompt(`Update "${field}" for ${jurisdiction.name}`, jurisdiction[field] || '')

			if (value !== null) {
				value = value.trim()

				if (value.length && value != jurisdiction[field]) {
					const toUpdate = {}
					toUpdate[field] = value

					heyGovApi
						.put(`/jurisdictions/${jurisdiction.id}`, toUpdate)
						.then(() => {
							jurisdiction[field] = value
							Vue.toasted.success(`Updated "${field}"`)
						})
						.catch(error => {
							Vue.toasted.error(`Failed to update "${field}": ${error.message}`)
						})
				} else if (value.length) {
					Vue.toasted.show('No update (value is the same)')
				} else {
					Vue.toasted.error('No update (value is empty)')
				}
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.loadJurisdictions()
			},
		},
	},
}
</script>
